import { Alert, Col, Row } from 'react-bootstrap'
import Rating from "~/src/Components/Rating/Rating";
import { Order, OrderSegment, OrderState } from '~/src/Model/Order/Order'

interface Props {
    order: Order,
    trackingCode: string
    estimatedTime: number
    estimatedTimeString: string
    estimatedTimeSinceString: string
}

export default function OrderSummary(props: Props) {
    const estimatedTime = () => {

        if (props.order.data.branch.segment === OrderSegment.DIET && [OrderState.NEW, OrderState.PICKUP].includes(props.order.data.state)) {
            return <h3 style={{color: props.order.data.branch.color}}>{props.estimatedTimeSinceString}&nbsp;-&nbsp;{props.estimatedTimeString}</h3>
        }

        if (props.order.data.state === OrderState.CANCELED) {
            return <h3 style={{color: props.order.data.branch.color}}>Stornováno</h3>
        }
        else if (props.order.data.state === OrderState.UNDELIVERED) {
            return <h3 style={{color: props.order.data.branch.color}}>Nedoručeno</h3>
        }
        else if (props.order.data.state === OrderState.DELIVERED) {
            return <h3 style={{color: props.order.data.branch.color}}>Doručeno</h3>
        }
        else if (props.order.data.state === OrderState.ARRIVED) {
            return <h3 style={{color: props.order.data.branch.color}}>Kurýr je na místě</h3>
        }

        return <h3 style={{color: props.order.data.branch.color}}>Do {props.estimatedTime.toFixed(0)} minut</h3>
    }

    return (
        <>
            <h1>{props.order.data.branch.name} děkuje za vaši objednávku #{props.trackingCode}</h1>
            <Row>
                <Col md={6} className="mt-20">
                    <p>Přibližný čas doručení</p>
                    {estimatedTime()}
                </Col>
                <Col md={6} className="mt-20">
                    <p>Adresa</p>
                    <h3 style={{color: props.order.data.branch.color}}>{props.order.data.customer.address}</h3>
                </Col>
                {!!props.order.data.extended_delivery_minutes && [OrderState.NEW, OrderState.PICKUP].includes(props.order.data.state) && (
                    <Alert variant="info" className="mt-20">
                        Moc nás to mrzí, ale Vaše objednavka bude mít <strong>{props.order.data.extended_delivery_minutes} minut</strong>  zpoždění. Nechceme vás nechat déle hladovět, proto udéláme maximum, abychom Vám jídlo doručili co nejdříve. Přijměte prosím naši omluvu.
                        (Prodloužený čas <strong>už je započítaný</strong> do přibližného času doručení)
                    </Alert>
                )}
            </Row>
            {props.order.data.branch.segment === OrderSegment.DIET && (
                <Row>
                    <Col md={6} className="mt-20">
                        <p>Telefon na dispečink</p>
                        <h3><a style={{color: props.order.data.branch.color}} href={`tel:${props.order.data.branch.phone_dispatching?.replace(/\s/g, '')}`}>{props.order.data.branch.phone_dispatching}</a></h3>
                    </Col>
                </Row>
            )}
            {props.order.data.evaluation_enabled &&
                <div className="mt-40">
                    <Rating
                        trackingCode={props.trackingCode}
                        color={props.order.data.branch.color}
                        submitDisabled={!(props.order.data.state === OrderState.DELIVERED && props.order.data.evaluation === null)}
                    />
                </div>
            }
        </>
    )
}
