import React, {ReactNode, useEffect, useMemo, useState} from 'react'
import TrackingLayout from '~/src/Components/TrackingLayout'
import OrderSummary from '~/src/Components/Order/OrderSummary'
import Header from '~/src/Components/Header/Header'
import {Container} from 'react-bootstrap'
import {useOrderFetch} from '~/src/Infrastructure/Order/OrderFetch'
import {useParams} from 'react-router-dom'
import OrderSummarySkeleton from '~/src/Components/Order/OrderSummarySkeleton'
import {useInterval} from '~/src/Infrastructure/Interval/Interval'
import {OrderState, StopStates} from '~/src/Model/Order/Order'
import Error404 from "~/src/Pages/Error404";
import MapSide from "~/src/Components/Order/MapSide";

function App() {
	const [fetchCompleted, setFetchCompleted] = useState<boolean>(false)
	const { trackingCode } = useParams()
	const { order, fetch, orderNotFound } = useOrderFetch(trackingCode!)
	const [estimatedTime, setEstimatedTime] = useState<number>(30)
	const [estimatedTimeString, setEstimatedTimeString] = useState<string>('')
	const [estimatedTimeSinceString, setEstimatedTimeSinceString] = useState<string>('')

	useEffect(() => {
		const fetchOrder = async () => {
			await fetch()
			setFetchCompleted(true)
		}
		if (!fetchCompleted) {
			fetchOrder()
		}
	}, [])

	useInterval(async () => {
		if (!order || !order?.success) {
			return
		}
		if (StopStates.includes(order.data.state)) {
			return
		}
		await fetch()
	}, 30000)

	useEffect(() => {
		if (order && [OrderState.NEW, OrderState.PICKUP].includes(order.data.state)) {
			const now = Math.floor(Date.now() / 1000)
			const deliveryDateTime = order.data.delivery_timestamp
			const estimatedTimeInSeconds = deliveryDateTime - now
			setEstimatedTime(estimatedTimeInSeconds / 60)

			if (order.data.branch.segment) {
				const estimatedTime = new Date(order.data.delivery_timestamp * 1000)
				if (order.data.extended_delivery_minutes !== null) {
					const x= estimatedTime.getMinutes() + order.data.extended_delivery_minutes
					setEstimatedTime(x)
				}
				setEstimatedTimeString((estimatedTime.getHours() <= 9 ? '0'+String(estimatedTime.getHours()) : estimatedTime.getHours()) + ':' + (estimatedTime.getMinutes() <= 9 ? '0'+String(estimatedTime.getMinutes()) : estimatedTime.getMinutes()))

				const estimatedTimeSince = new Date(order.data.delivery_since_timestamp * 1000)
				setEstimatedTimeSinceString((estimatedTimeSince.getHours() <= 9 ? '0'+String(estimatedTimeSince.getHours()) : estimatedTimeSince.getHours()) + ':' + (estimatedTimeSince.getMinutes() <= 9 ? '0'+String(estimatedTimeSince.getMinutes()) : estimatedTimeSince.getMinutes()))
			}
		}

		if (order) {
			document.title = `#${trackingCode} - ${order.data.branch.name}`
		}
	}, [order])

	const onEstimatedTimeChangedHandler = (estimatedTime: number) => {
		setEstimatedTime(estimatedTime)
	}

	useInterval(() => {
		if (order?.data.state === OrderState.DELIVERING) {
			return
		}
		if (estimatedTime <= 2) {
			return
		}
		setEstimatedTime(time => time - 1)
	}, 60000)


	const orderSide = useMemo<ReactNode>(() => {
		if (!fetchCompleted) {
			return <OrderSummarySkeleton />
		}
		return (order && <OrderSummary
			order={order}
			estimatedTime={estimatedTime}
			trackingCode={trackingCode!}
			estimatedTimeString={estimatedTimeString}
			estimatedTimeSinceString={estimatedTimeSinceString}
		/>)
	}, [order, fetchCompleted, estimatedTime])


	const mapSide = useMemo<ReactNode>(() => {
		if (!fetchCompleted) {
			return <OrderSummarySkeleton />
		}
		if (order) {
			return <MapSide order={order.data} trackingCode={trackingCode!} onEstimatedTimeChangedHandler={onEstimatedTimeChangedHandler}/>
		}
	}, [order, fetchCompleted])

	if (orderNotFound) {
		return <Error404/>
	}

	return (
		<div className="app-container">
			<Container>
				{order && <Header orderBranch={order.data.branch}/> }
			</Container>
			<div className="mt-80">
				<TrackingLayout map={mapSide} order={orderSide}/>
			</div>
		</div>
	)
}

export default App
